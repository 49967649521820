// VENDORS
@@include("./vendors/jquery/jquery-3.1.1.min.js")
@@include("./vendors/jquery/jquery.ba-throttle-debounce.js")
@@include("./vendors/greensock/minified/TweenLite.min.js")
@@include("./vendors/greensock/minified/plugins/CSSPlugin.min.js")
@@include("./vendors/scrollmagic/minified/ScrollMagic.min.js")
@@include("./vendors/scrollmagic/minified/plugins/animation.gsap.min.js")
@@include("./vendors/slick/slick.min.js")
@@include("./vendors/cycle2/jquery.cycle2.min.js")
@@include("./vendors/instafeed.min.js")
